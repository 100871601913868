var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "text-center" },
        [_c("v-col", [_c("h2", [_vm._v("NCU overview")])])],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex flex-column",
                  attrs: { flat: "", color: "transparent", height: "100%" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "list-item pa-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v("mdi-memory"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-h6 secondary--text list-title",
                                },
                                [_vm._v("NCU utilization")]
                              ),
                              _c(
                                "v-list-item-subtitle",
                                { staticClass: "list-subtitle" },
                                [
                                  _vm._v(
                                    "in hours (" +
                                      _vm._s(
                                        _vm.ncuUtilizationTimelineTimeUnit
                                      ) +
                                      ")"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "flex-grow-1" },
                    [
                      _vm.fetchingCharts.ncu_utilization_timeline
                        ? _c("dashboard-loader")
                        : [
                            _vm.hasData(_vm.charts.ncu_utilization_timeline) &&
                            !_vm.$isError(_vm.charts.ncu_utilization_timeline)
                              ? _c("bar-chart", {
                                  attrs: {
                                    chartData: _vm.ncuUtilizationTimeline,
                                    options: {
                                      legend: false,
                                      yTitle: "NCU hours",
                                    },
                                  },
                                })
                              : _vm._e(),
                            !_vm.hasData(_vm.charts.ncu_utilization_timeline)
                              ? _c("dashboard-empty")
                              : _vm._e(),
                            _vm.$isError(_vm.charts.ncu_utilization_timeline)
                              ? _c("dashboard-error")
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c(
                "v-card",
                { attrs: { height: "100%" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "list-item pa-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v("mdi-memory"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-h6 secondary--text list-title",
                                },
                                [_vm._v("NCU utilization")]
                              ),
                              _c(
                                "v-list-item-subtitle",
                                { staticClass: "list-subtitle" },
                                [_vm._v("in hours")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "flex-grow-1 text-center" },
                    [
                      _vm.fetchingCharts.ncu_utilization
                        ? _c("dashboard-loader")
                        : [
                            _vm.hasData(_vm.charts.ncu_utilization) &&
                            !_vm.$isError(_vm.charts.ncu_utilization)
                              ? [
                                  _c("div", [_vm._v("A total of")]),
                                  _c(
                                    "div",
                                    { staticClass: "text-h4 secondary--text" },
                                    [_vm._v(_vm._s(_vm.totalNcuUtilization))]
                                  ),
                                  _c("div", { staticClass: "pb-8" }, [
                                    _vm._v("NCU hours"),
                                  ]),
                                  _c("doughnut-chart", {
                                    attrs: {
                                      chartData: _vm.ncuUtilization,
                                      rpid: _vm.rpid,
                                    },
                                    on: {
                                      legendClick:
                                        _vm.legendClickNcuUtilization,
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            !_vm.hasData(_vm.charts.ncu_utilization)
                              ? _c("dashboard-empty")
                              : _vm._e(),
                            _vm.$isError(_vm.charts.ncu_utilization)
                              ? _c("dashboard-error")
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "text-center mt-10" },
        [_c("v-col", [_c("h2", [_vm._v("Filesystem overview")])])],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "transparent", height: "100%" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "list-item pa-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v("mdi-folder-network-outline"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-h6 secondary--text list-title",
                                },
                                [_vm._v("Filesystem utilization")]
                              ),
                              _c(
                                "v-list-item-subtitle",
                                { staticClass: "list-subtitle" },
                                [
                                  _vm._v(
                                    "in GBs (" +
                                      _vm._s(
                                        _vm.fsUtilizationTimelineTimeUnit
                                      ) +
                                      ")"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "flex-grow-1" },
                    [
                      _vm.fetchingCharts.fs_utilization_timeline
                        ? _c("dashboard-loader")
                        : [
                            _vm.hasData(_vm.charts.fs_utilization_timeline) &&
                            !_vm.$isError(_vm.charts.fs_utilization_timeline)
                              ? [
                                  _c("bar-chart", {
                                    attrs: {
                                      chartData: _vm.fsUtilizationTimeline,
                                      options: { legend: false, yTitle: "GB" },
                                    },
                                  }),
                                  _vm.showUsageLimitLabel
                                    ? _c(
                                        "div",
                                        { staticClass: "pt-2 text-center" },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "#ef7d57" } },
                                            [_vm._v("mdi-circle")]
                                          ),
                                          _vm._v(" Usage limit (GB) "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            !_vm.hasData(_vm.charts.fs_utilization_timeline)
                              ? _c("dashboard-empty")
                              : _vm._e(),
                            _vm.$isError(_vm.charts.fs_utilization_timeline)
                              ? _c("dashboard-error")
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c(
                "v-card",
                { attrs: { height: "100%" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "list-item pa-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v("mdi-folder-network-outline"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-h6 secondary--text list-title",
                                },
                                [_vm._v("Filesystem utilization")]
                              ),
                              _vm.fsUtilizationDate
                                ? _c(
                                    "v-list-item-subtitle",
                                    { staticClass: "list-subtitle" },
                                    [
                                      _vm._v(
                                        "in GBs as of " +
                                          _vm._s(_vm.fsUtilizationDate)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "flex-grow-1 text-center" },
                    [
                      _vm.fetchingCharts.fs_utilization
                        ? _c("dashboard-loader")
                        : [
                            _vm.hasData(_vm.charts.fs_utilization) &&
                            !_vm.$isError(_vm.charts.fs_utilization)
                              ? [
                                  _c("div", [_vm._v("A total of")]),
                                  _c(
                                    "div",
                                    { staticClass: "text-h4 secondary--text" },
                                    [_vm._v(_vm._s(_vm.totalFsUtilization))]
                                  ),
                                  _c("div", { staticClass: "pb-8" }, [
                                    _vm._v("GBs"),
                                  ]),
                                  _c("doughnut-chart", {
                                    attrs: {
                                      chartData: _vm.fsUtilization,
                                      rpid: _vm.rpid,
                                    },
                                    on: {
                                      legendClick: _vm.legendClickFsUtilization,
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            !_vm.hasData(_vm.charts.fs_utilization)
                              ? _c("dashboard-empty")
                              : _vm._e(),
                            _vm.$isError(_vm.charts.fs_utilization)
                              ? _c("dashboard-error")
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "text-center mt-10" },
        [_c("v-col", [_c("h2", [_vm._v("User activity overview")])])],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "transparent", height: "100%" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "list-item pa-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v("mdi-console"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-h6 secondary--text list-title",
                                },
                                [_vm._v("Number of application runs")]
                              ),
                              _vm.fsUtilizationDate
                                ? _c(
                                    "v-list-item-subtitle",
                                    { staticClass: "list-subtitle" },
                                    [
                                      _vm._v(
                                        " (" +
                                          _vm._s(
                                            _vm.appUtilizationTimelineTimeUnit
                                          ) +
                                          ") "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "flex-grow-1" },
                    [
                      _vm.fetchingCharts.app_utilization_timeline
                        ? _c("dashboard-loader")
                        : [
                            _vm.hasData(_vm.charts.app_utilization_timeline) &&
                            !_vm.$isError(_vm.charts.app_utilization_timeline)
                              ? _c("bar-chart", {
                                  attrs: {
                                    chartData: _vm.appUtilizationTimeline,
                                    options: {
                                      legend: false,
                                      precision: 0,
                                      yTitle: "Application runs",
                                    },
                                  },
                                })
                              : _vm._e(),
                            !_vm.hasData(_vm.charts.app_utilization_timeline)
                              ? _c("dashboard-empty")
                              : _vm._e(),
                            _vm.$isError(_vm.charts.app_utilization_timeline)
                              ? _c("dashboard-error")
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }